
import { Controller } from "../../node_modules/@hotwired/stimulus/dist/stimulus.js"

export default class extends Controller {
  static targets = ['main', 'headline', 'subtext', 'fill']

  connect () {
    this.timeout = 90
    this.line = 0
    this.char = 0

    setTimeout(() => {
      if(this.subtextTargets[this.line]){
        this.typeLine(this.subtextTargets[this.line].textContent.trim())
      }
    }, 1000)
  }

  typeLine(text) {
    if (this.char > text.length) {
      this.char = 0
      this.line ++
      this.fillTarget.innerHTML += '<br/>'
    } else {
      this.fillTarget.innerHTML += text.charAt(this.char);
      this.char++
    }

    if(this.subtextTargets[this.line]){
      setTimeout(() => {
        this.typeLine(this.subtextTargets[this.line].textContent.trim())
      }, this.timeout)
    }
  }
}
