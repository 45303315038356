import { Controller } from "../../node_modules/@hotwired/stimulus/dist/stimulus.js"

export default class extends Controller {
  static targets = ['bgElement', 'followElement']

  connect () {
  }

  onScroll() {
    this.bgElementTarget.style.setProperty('top', `${(8*16) -this.followElementTarget.scrollTop}px`)
  }

  resetScroll() {
    this.followElementTarget.scrollTo(0, 0)
    this.onScroll(event)
  }

}
