import { Controller } from "../../node_modules/@hotwired/stimulus/dist/stimulus.js"

import Background from "./background.js"
import Params from "./params.js"

export default class extends Controller {
  static values = {
    loading: String,
    unloading: String,
  }

  connect () {
  }

  navigate(event) {
    let link = event.currentTarget

    setTimeout(() => {
      this.setQueryParam('p', link.dataset.path)
      this.route()
    }, parseInt(link.dataset.routerDelay) || 0);
  }

  isCurrent() {
    return Params.page() == this.element.dataset.routerPage
  }

  display (event) {
    if(!this.isCurrent()) return false
    if(this.loadingValue) {
      Background.set(this.loadingValue)
    }

    setTimeout(() => {
      this.element.querySelectorAll(".loading-animation").forEach((element, index ) => {
        element.classList.remove('fade-in-text', 'scale-up')
      })
    }, 1000);
  }

  unload() {
    if(!this.isCurrent()) return false

    if(this.unloadingValue) {
      Background.set(this.unloadingValue)
    }

    this.element.querySelectorAll(".loading-animation").forEach((element, index ) => {
      if(element.dataset.animation) {
        element.classList.add(element.dataset.animation)
      }
    })
  }
}
