export default class {
  static key = window.location.search.split("?")[1]
  static pathname = window.location.pathname

  static routing(){
    return this.queryParams().get('p') ? true : false
  }

  static page(){
    return this.queryParams().get('p')
  }

  static scrollablePage() {
    return !((this.page() == 'intro') || (this.page() == null))
  }

  static bgPage() {
    return !((this.page() == 'impact') || (this.page() == 'headquarters'))
  }

  static bgFullscreen() {
    return this.page() == null
  }

  static bgAnimate() {
    return ['inspire', 'support', 'teach', 'connect', 'unite'].includes(this.page())
  }

  static developmentEnv() {
    return window.location.hostname.toLowerCase() == 'localhost'
  }

  static queryParams() {
    return new URLSearchParams(window.location.search);
  }
}
