import { Controller } from "../../node_modules/@hotwired/stimulus/dist/stimulus.js"

export default class extends Controller {
  static targets = ['button']

  connect () {
  }

  click (event) {
    let button = event.currentTarget

    this.buttonTargets.forEach((element, index ) => {
      element.classList.add('scale-0')
    })

    button.classList.add('md:scale-150', 'md:enter-button')

    setTimeout(() => {
      button.classList.remove('md:scale-150', 'md:enter-button')
      button.classList.add('md:scale-0')
      window.dispatchEvent(new CustomEvent('reset-scroll'));
    }, 1000);
  }
}
