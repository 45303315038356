import Reveal from "../../node_modules/stimulus-reveal-controller/dist/stimulus-reveal-controller.mjs"
import { useClickOutside } from '../../node_modules/stimulus-use/dist/index.js'

export default class extends Reveal {
  connect() {
    super.connect()
    useClickOutside(this)
  }

  clickOutside(event) {
    // if(event){ event.preventDefault() }

    this.hide()
  }
}
