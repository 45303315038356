import opener from '../images/opener_full_screen.jpg'
import building_base from '../images/opener_with_building.png'

import classroom_open from '../images/classroom_open.gif'
import classroom_close from '../images/classroom_close.gif'

import auditorium_open from '../images/auditorium_open.gif'
import auditorium_close from '../images/auditorium_close.gif'

import atrium_open from '../images/atrium_open.gif'
import atrium_close from '../images/atrium_close.gif'

import labs_open from '../images/labs_open.gif'
import labs_close from '../images/labs_close.gif'

import workspace_open from '../images/workspace_open.gif'
import workspace_close from '../images/workspace_close.gif'

export default class {
  static key = window.location.search.split("?")[1]

  static images = {
    'building_base': building_base,
    'opener': opener,

    'classroom_open': classroom_open,
    'classroom_close': classroom_close,

    'auditorium_open': auditorium_open,
    'auditorium_close': auditorium_close,

    'atrium_open': atrium_open,
    'atrium_close': atrium_close,

    'labs_open': labs_open,
    'labs_close': labs_close,

    'workspace_open': workspace_open,
    'workspace_close': workspace_close
  }

  static currentImage() {
    return document.getElementsByClassName("bg-image z-20")[0]
  }

  static nextImage() {
    return document.getElementsByClassName("bg-image z-10")[0]
  }

  static currently(image) {
    return this.currentImage().src == this.images[image]
  }

  static set(image) {
    let bg = this.currentImage()
    let nextBg = this.nextImage()

    nextBg.src = (this.images[image] || '').replace(/\?.*/, '')

    bg.classList.remove('z-20')
    bg.classList.add('z-10')
    nextBg.classList.remove('z-10')
    nextBg.classList.add('z-20')

    setTimeout(() => {
      bg.removeAttribute('src')
    }, 100);
  }
}
