import { Controller } from "../../node_modules/@hotwired/stimulus/dist/stimulus.js"

import Background from "./background.js"
import Params from "./params.js"

export default class extends Controller {
  connect () {
  }

  display (event) {
    if(Params.page() == this.element.dataset.routerPage) {
      let bgImage = this.element.dataset.bgImage
      Background.set(bgImage)
    }
  }
}
