import { Controller } from "../../node_modules/@hotwired/stimulus/dist/stimulus.js"
import throttle from 'lodash/throttle'

import Params from "./params.js"
import Background from "./background.js"

export default class extends Controller {
  static targets = ['scrollElement']

  static values = {}

  connect () {
    this.scrollDirection = null;
    this.onScrollRunning = null;
    this.headingIndex = 0

    this.throttleScrollDown = throttle(this.scrollDown, 300).bind(this)
    this.throttleScrollUp = throttle(this.scrollUp, 300).bind(this)

    if(!Params.routing()) this.slideOnDisplay(this.currentHeading())
  }

  scrollUp(e) {
    if (this.onScrollRunning) return

    if(this.previousHeading()) {
      this.onScrollRunning = true
      // console.log(`start scroll down ${this.nextHeading()} top: `, getComputedStyle(this.nextHeading()).top)

      this.slideOffDisplay(this.currentHeading(), 'down')
      this.slideOnDisplay(this.previousHeading())

      setTimeout(() => {
        this.headingIndex--
        this.onScrollRunning = false
        // console.log(`after animation down -- heading: ${this.headingIndex} running: ${this.onScrollRunning}`)
      }, 1500);
    }
  }

  scrollDown(e) {
    if (this.onScrollRunning) return

    if(this.nextHeading()) {
      this.onScrollRunning = true
      // console.log(`start scroll down ${this.nextHeading()} top: `, getComputedStyle(this.nextHeading()).top)

      this.slideOffDisplay(this.currentHeading(), 'up')
      this.slideOnDisplay(this.nextHeading())

      setTimeout(() => {
        this.headingIndex++
        this.onScrollRunning = false
      }, 1500);
    }
  }

  setHeading(event) {
    let triggerHeadingDelay = parseInt(event.detail.delay) || 700
    this.headingIndex = parseInt(event.detail.heading)
    let slide = this.currentHeading()

    this.hideSlides()
    setTimeout(() => {
      this.slideOnDisplay(slide, triggerHeadingDelay)
    }, 100);
  }

  hideSlides() {
    this.scrollElementTargets.forEach((element, index ) => {
      element.classList.add('hidden')
    })
  }

  previousHeading() {
    return this.scrollElementTargets[this.headingIndex - 1]
  }

  currentHeading() {
    return this.scrollElementTargets[this.headingIndex]
  }

  nextHeading() {
    return this.scrollElementTargets[this.headingIndex + 1]
  }

  slideOnDisplay(slide, delay=700) {
    slide.animate({
        top: [getComputedStyle(slide).top, '8rem']
      }, {
        duration: 700,
        easing: 'ease-in-out',
        iterations: 1,
        fill: "forwards"
      }
    )

    slide.querySelectorAll(".slide-parent").forEach((element, index ) => {
      element.classList.remove('hidden')
    })

    if(slide.dataset.bgFullscreen){
      document.getElementById("fs-bg").classList.remove('opacity-0')
    } else {
      document.getElementById("fs-bg").classList.add('opacity-0')
    }

    setTimeout(() => {
      let bgImage = slide.dataset.bgImage
      slide.classList.remove('hidden')

      slide.querySelectorAll(".scale-0").forEach((element, index ) => {
        element.classList.remove('scale-0', 'md:scale-0')
      })

      window.dispatchEvent(new CustomEvent('scroll-complete'))

      if(bgImage && !Background.currently(bgImage)) {
        Background.set(bgImage)
      }
    }, delay);
  }

  slideOffDisplay(slide, direction) {
    if(direction == 'down') {
      this.currentHeading().animate({
          top: [getComputedStyle(slide).top, 'calc(100% - 3rem)']
        }, {
          duration: 700,
          easing: 'ease-in-out',
          iterations: 1,
          fill: "forwards"
        }
      )
    }
    if(direction == 'up') {
      slide.animate({
          top: [getComputedStyle(slide).top, '-400px']
        }, {
          duration: 700,
          easing: 'ease-in-out',
          iterations: 1,
          fill: "forwards"
        }
      )
    }

    slide.querySelectorAll(".area-button").forEach((element, index ) => {
      element.classList.add('scale-0')
    })
  }
}
