import { Controller } from "../../node_modules/@hotwired/stimulus/dist/stimulus.js"
import Params from "./params.js"
import Background from "./background.js"

export default class extends Controller {
  static targets = ['page']

  connect () {
    this.givingPages = ['inspire', 'support', 'teach', 'connect', 'unite']

    if(Params.bgPage()) {
      this.showBackgrounds()
    }

    setTimeout(() => {
      this.route()
    }, 200);
  }

  showBackgrounds() {
    setTimeout(() => {
      document.getElementById("animate-bg").classList.remove('hidden')
    }, Params.bgAnimate() ? 0 : 700);

    setTimeout(() => {
      document.getElementById("fs-bg").classList.remove('hidden')
    }, Params.bgFullscreen() ? 0 : 700);
  }

  route(triggerHeadingDelay=null) {
    let path = Params.queryParams().get('p')
    if(!path) path = 'intro'

    this.pageTargets.forEach((page, index ) => {
      let show = (path == page.dataset.routerPage) || this.pageMatch(page, path)

      if (show) {
        page.classList.remove('hidden')

        if(Params.bgPage()) {
          this.showBackgrounds()
        }

        if(Params.routing() && page.dataset.routerTriggerHeading) {
          let headingEvent = (new CustomEvent('trigger-heading', {
            detail: {
              heading: page.dataset.routerTriggerHeading,
              delay: triggerHeadingDelay
            }
          }))
          window.dispatchEvent(headingEvent);
        }

        if(page.dataset.routerPageType == 'giving') {
          document.getElementById("fs-bg").classList.add('opacity-0')
          let pageEvent = (new CustomEvent('giving-page'))
          window.dispatchEvent(pageEvent);
        }

        if(page.dataset.routerPageType == 'custom') {
          document.getElementById("fs-bg").classList.add('opacity-0')
          let pageEvent = (new CustomEvent('custom-page'))
          window.dispatchEvent(pageEvent);
        }
      } else {
        page.classList.add('hidden')
      }
    })
  }

  unloadPage() {
    let unload = (new CustomEvent('unload'))
    window.dispatchEvent(unload);
  }

  quickNavigate(event) {
    this.navigate(event, null, 100)
  }

  navigateAnimated(event) {
    let path = event.currentTarget.dataset.path
    let target = event.currentTarget

    let currentPath = Params.page()
    let currentPage = this.pageTargets.find( target => {
      return target.dataset.routerPage == currentPath
    })
    if(!currentPage) return

    if(currentPage.dataset.routerPageType == 'giving') {
      this.unloadPage()
      setTimeout(() => {
        this.navigate(event, path, null, target)
      }, 1000);
    } else {
      this.navigate(event, path)
    }
  }

  navigate(event, path=null, triggerHeadingDelay=null, target=null) {
    let link = event.currentTarget || target
    path = path ? path : link.dataset.path

    setTimeout(() => {
      this.setQueryParam('p', path)
      this.route(triggerHeadingDelay)
      if(link.dataset.resetScroll) {
        window.dispatchEvent(new CustomEvent('reset-scroll'));
      }
    }, parseInt(link.dataset.routerDelay) || 0);
  }

  nextGiving(event) {
    let currentPath = Params.page()
    let currentPage = this.pageTargets.find( target => {
      return target.dataset.routerPage == currentPath
    })
    if(!currentPage) return

    let nextIndex = this.givingPages.indexOf(currentPath) + 1
    if(nextIndex > (this.givingPages.length - 1)) nextIndex = 0

    let path = this.givingPages[nextIndex]
    if(!path) return

    this.unloadPage()
    this.navigate(event, path)
  }

  prevGiving(event) {
    let currentPath = Params.page()
    let currentPage = this.pageTargets.find( target => {
      return target.dataset.routerPage == currentPath
    })
    if(!currentPage) return

    let prevIndex = this.givingPages.indexOf(currentPath) - 1
    if(prevIndex < 0) prevIndex = (this.givingPages.length - 1)

    let path = this.givingPages[prevIndex]
    if(!path) return

    this.unloadPage()
    this.navigate(event, path)
  }

  setQueryParam(key, value) {
    let queryParams = Params.queryParams()

    if (Array.isArray(value)) {
      queryParams.delete(key)
      value.reduce((acc, cur) => { acc.append(key, cur); return acc; }, queryParams)
    } else {
      queryParams.set(key, value);
    }

    history.pushState(null, '', "?" + queryParams.toString() + location.hash);
  }

  popstate() {
    setTimeout(() => {
      this.route()
    }, 200);
  }

  deleteQueryParam(key) {
    let queryParams = Params.queryParams()

    queryParams.delete(key)

    history.pushState(null, '', "?" + queryParams.toString() + location.hash);
  }

  pageMatch(page, path) {
    if (!page.dataset.routerMatch) return
    return page.dataset.routerMatch.split(',').includes(path)
  }

}
