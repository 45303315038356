import { Application } from "../node_modules/@hotwired/stimulus/dist/stimulus.js"

import RouterController from "./js/router_controller.js"
import HeadingScrollController from "./js/heading_scroll_controller.js"
import BackgroundScrollController from "./js/background_scroll_controller.js"
import CaptureScrollController from "./js/capture_scroll_controller.js"
import TypewriterController from "./js/typewriter_controller.js"
import GivingNavController from "./js/giving_nav_controller.js"
import GivingPageController from "./js/giving_page_controller.js"
import CustomPageController from "./js/custom_page_controller.js"

import RevealController from "./js/reveal_with_outside_controller.js"

window.Stimulus = Application.start()

Stimulus.register("router", RouterController)
Stimulus.register("heading-scroll", HeadingScrollController)
Stimulus.register("background-scroll", BackgroundScrollController)
Stimulus.register("capture-scroll", CaptureScrollController)
Stimulus.register("typewriter", TypewriterController)
Stimulus.register("giving-nav", GivingNavController)
Stimulus.register("giving-page", GivingPageController)
Stimulus.register("custom-page", CustomPageController)

Stimulus.register("reveal", RevealController)
