import { Controller } from "../../node_modules/@hotwired/stimulus/dist/stimulus.js"

import Params from "./params.js"

export default class extends Controller {
  connect () {
    // modern Chrome requires { passive: false } when adding event
    this.supportsPassive = false;
    this.touchY = null
    let controller = this

    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { controller.supportsPassive = true }
      }));
    } catch(e) {}

    this.wheelOpt = this.supportsPassive ? { passive: false } : false;
    this.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    setTimeout(() => {
      this.startScrollCapture()
    }, 100);
  }

  scrollComplete(event) {
    this.touchY = null
  }

  captureScroll(event) {
    let customEvent

    if(Params.scrollablePage()) {
      window.dispatchEvent(new CustomEvent('scroll'))
    } else {
      if(event.touches) {
        let eventY = event.touches[0].clientY

        if( this.touchY ) {
          customEvent = eventY && (eventY < this.touchY) ? 'scroll-down' : 'scroll-up'
        }

        this.touchY = eventY
      } else {
        if( event.deltaY ) {
          customEvent = (event.deltaY > 0) ? 'scroll-down' : 'scroll-up'
        }
      }

      if( customEvent ) window.dispatchEvent(new CustomEvent(customEvent))
      event.preventDefault();
    }
  }

  captureScrollForScrollKeys(e) {
    let keys = {37: 'scroll-up', 38: 'scroll-up', 39: 'scroll-down', 40: 'scroll-down'}

    if (keys[e.keyCode]) {
      window.dispatchEvent(new CustomEvent(keys[e.keyCode]))

      if(Params.scrollablePage()) {
        window.dispatchEvent(new CustomEvent('scroll'))
      } else {
        e.preventDefault(e);
        return false;
      }
    }
  }

  startScrollCapture() {
    this.element.addEventListener('DOMMouseScroll', this.captureScroll, false); // older FF
    this.element.addEventListener(this.wheelEvent, this.captureScroll, this.wheelOpt); // modern desktop
    this.element.addEventListener('touchmove', this.captureScroll, this.wheelOpt); // mobile
    this.element.addEventListener('keydown', this.captureScrollForScrollKeys, false)

    this.element.addEventListener('touchend', this.scrollComplete, this.wheelOpt); // mobile
  }

  endScrollCapture() {
    this.element.removeEventListener('DOMMouseScroll', this.captureScroll, false);
    this.element.removeEventListener(this.wheelEvent, this.captureScroll, this.wheelOpt);
    this.element.removeEventListener('touchmove', this.captureScroll, this.wheelOpt);
    this.element.removeEventListener('keydown', this.captureScrollForScrollKeys, false);
  }
}
